import React, { useState, useEffect } from 'react';
import { Redirect, useHistory } from 'react-router-dom';
import logo from '../img/logo_white.png';
import Cookies from 'js-cookie';
import './css/LoginForm.css';

const LoginForm = () => {

  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [redirectTo, setRedirectTo] = useState(null); // État pour suivre la redirection
  const history = useHistory();

  const handleSubmit = async (event) => {
    event.preventDefault();
    setError('');

    try {
      const response = await fetch('https://test.elyma.ca/backend/api/login.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ username, password }),
      });

      const result = await response.json();

      if (response.ok && result.status === 'success') {
        const usernameCookie = Cookies.set('username', username);
        if (username === 'coachsab') {
          setRedirectTo('/elses'); // Définir la destination de la redirection
        } else {
          setRedirectTo('/dashboard'); // Définir la destination de la redirection
        }
      } else {
        setError(result.message || 'Nom d\'utilisateur ou mot de passe erroné.');
      }
    } catch (error) {
      setError('Une erreur est survenue. Veuillez réessayer.');
    }
  };

  useEffect(() => {
    // Rediriger après un court délai si redirectTo est défini
    if (redirectTo) {
      const timeout = setTimeout(() => {
        history.push(redirectTo);
        window.location.reload(true); // Forcer le rafraîchissement de la page
      }, 1000);

      return () => clearTimeout(timeout);
    }
  }, [redirectTo, history]);

  return (

    <div className="login-form-container">
      {/* Logo */}
      <div className='logo-container'>
        <img src={logo} className="App-logo" alt="logo" />
      </div>

      {/* Formulaire */}
      <form id="loginForm" onSubmit={handleSubmit} className="login-form">
        <label htmlFor="username">Adresse courriel :</label>
        <input
          type="text"
          id="username"
          name="username"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          required
        />
        <label htmlFor="password">Mot de passe :</label>
        <input
          type="password"
          id="password"
          name="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          required
        />
        <button type="submit">Connexion</button>
        <a href="/passwordreset.php">Mot de passe oublié</a>
        {error && <div className="error-message">{error}</div>}
      </form>
    </div>
  );
};

export default LoginForm;
