// DashboardWelcome.js

import React from 'react';
import '../css/Dashboard.css';

const DashboardWelcome = ({ prenom }) => {
  return (
    <div id="img-block-alystar">
      <h4>Bienvenue {prenom}</h4>
      <p>Restez en contact avec l’entraîneur de votre enfant où que vous soyez en tout temps!</p>
    </div>
  );
};

export default DashboardWelcome;
