// Header.js
import React from 'react';
import { useEffect } from 'react';
import logo from '../img/logo_black.png'
import './Header.css';

const Header = () => {
  useEffect(() => {
    console.log("Header component mounted");
  }, []);
  return (
    <div className="header">
        <img src={logo} className="header-logo" alt="logo" />
    </div>
  );
};

export default Header;