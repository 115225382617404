// Dashboard.js

import React from 'react';
import Header from '../components/Header';
import Sidebar from '../components/Sidebar';
import DashboardWelcome from './forms-contents/dashboard-welcome';

const Dashboard = () => {
  const prenom = "VotrePrénom"; // Remplacez par la valeur dynamique si nécessaire

  return (
    <div className="dashboard">
      <Header />
      <Sidebar />
      <DashboardWelcome prenom={prenom} />

      <h1>Dashboard</h1>
      <p>This is the dashboard page.</p>
    </div>
  );
};

export default Dashboard;