import React from 'react';
import { useEffect } from 'react';
import './Sidebar.css';
import { NavLink, useHistory } from 'react-router-dom';
import Cookies from 'js-cookie';
import home from '../img/home.png';
import user from '../img/user.png'
import config from '../img/config.png'
import logout from '../img/logout.png'
import { color } from 'three/examples/jsm/nodes/Nodes.js';

const Sidebar = () => {
  useEffect(() => {
    console.log("Sidebar component mounted");
  }, []);

  const history = useHistory();

  const refreshPageAndRedirect = (url) => {
    history.push(url); // Redirection d'abord
    setTimeout(() => {
      window.location.reload(); // Ensuite, rafraîchissement de la page
    }, 100); // Définissez un délai d'attente de 100 millisecondes avant le rafraîchissement
  };
  const userAdmin = Cookies.get('username')
  return (

    <div className="sidebar">
      <ul>
        <li><img src={home} width={'25px'} /><NavLink to="/dashboard" onClick={() => refreshPageAndRedirect('/dashboard')}>Accueil</NavLink></li>
        <li><img src={user} width={'25px'} /><NavLink to="/profile" onClick={() => refreshPageAndRedirect('/profile')}>Profil</NavLink></li>
        <li><img src={config} width={'25px'} /><NavLink to="/settings" onClick={() => refreshPageAndRedirect('/settings')}>Paramètres</NavLink></li>
        <li><img src={logout} width={'25px'} /><NavLink to="/logout" onClick={() => refreshPageAndRedirect('/logout')}>Déconnexion</NavLink></li>

        {userAdmin == 'nicolas.st-p@live.ca' && (
          <li><img src={user} width={'25px'} /><NavLink to="/admin" onClick={() => refreshPageAndRedirect('/admin')}>Admin</NavLink></li>
        )}
      </ul>
    </div>
  );
};

export default Sidebar;
