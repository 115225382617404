// Profile.js
import React from 'react';
import './css/Profile.css';
import Header from '../components/Header';
import Sidebar from '../components/Sidebar';

const Profile = () => {
    return (

        <div className='profile'>
            <Header />
            <Sidebar />
            <h1>Profile</h1>
            <p>This is the profile page.</p>
        </div>
    );
};

export default Profile;