import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect, useLocation } from 'react-router-dom';
import LoginForm from './Forms/LoginForm.js';
import Dashboard from './Forms/Dashboard.js';
import Profile from './Forms/Profile.js';
import Settings from './Forms/Settings.js';
import Header from './components/Header.js';
import Sidebar from './components/Sidebar.js';
import './App.css';

function App() {
  return (
    <Router>
      <AppContent />
    </Router>
  );
}

function AppContent() {
  const [shouldRenderComponents, setShouldRenderComponents] = useState(false);
  const location = useLocation();

  useEffect(() => {
    // Mettre à jour l'état de shouldRenderComponents en fonction de l'emplacement actuel
    setShouldRenderComponents(location.pathname !== '/' && location.pathname !== '/logout');
  }, [location]);

  return (
    <div className="App">
      {shouldRenderComponents && <Header />}
      {shouldRenderComponents && <Sidebar />}
      <div className="app-container">
        <Switch>
          <Route exact path="/" component={LoginForm} />
          <Route path="/dashboard" component={Dashboard} />
          <Route path="/profile" component={Profile} />
          <Route path="/settings" component={Settings} />
          <Route path="/logout" component={LoginForm} />
          <Route path="*">
            <Redirect to="/" />
          </Route>
        </Switch>
      </div>
    </div>
  );
}

export default App;
